
export default {
  data() {
    return {
      custId:'',
      contact:''
    };
  },
  mounted() {
    this.custId = this.$route.query.custId
    this.contact = this.$route.query.contact
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/index" });
    }
  }
};